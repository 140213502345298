import {Fragment, useEffect, useState, useRef} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import history from '../history';
import axios from 'axios';
import { apiUrl } from '../Helpers/General';
import { pageTransition, goTo404 } from '../Helpers/Func';
import { translations } from '../Helpers/Lang';

import Media from '../Components/Media';
// import Lightbox from '../Components/Lightbox';

import SectionPropagator from '../Components/SectionPropagator';
import SectionHead from '../Components/Sections/SectionHead';
import GoToSeason from '../Components/GoToSeason';



import Footer from '../Components/Footer';


const lastgotoPageSlugs = {
	hr: 'eurokaz-produkcija',
	en: 'eurokaz-production'
}


function Season(props){

	const { 
		language,
		slug 
	} = useParams();

	const itemsRef = useRef({});

	const [data, setData] = useState([]);
	const [lists, setLists] = useState([]);
	const [lastgotoPage, setLastGotoPage] = useState([]);
	const [season, setSeason] = useState(null);

	const [modalArticle, setModalArticle] = useState(null);

	// eslint-disable-next-line
	const [pageScrollIcon, setPageScrollIcon] = 			useState(true);

	const navigate = useNavigate();



	useEffect(() => {

		pageTransition();

		if( props.lang !== '' ){
			axios.get(`${apiUrl}${props.lang}/api/lists`)
				.then(res => res.data)
				.then(data => {
					setLists(data);
				});

			axios.get(`${apiUrl}${props.lang}/api/lists/${slug}`)
				.then(res => res.data)
				.then(data => {
					
					if( data.length === 0){
						goTo404(language, history, navigate);
						return false;
					}

					setSeason(data[0]);
				});

			axios.get(`${apiUrl}${props.lang}/api/items/${slug}`)
				.then(res => res.data)
				.then(data => {
					setData(data);
					window.scrollTo(0, 0);
				});

			axios.get(`${apiUrl}${props.lang}/api/pages/${lastgotoPageSlugs[props.lang]}`)
				.then(res => res.data)
				.then(data => {
					setLastGotoPage(data);
				});

			[...document.querySelectorAll('.fx')].forEach((elem, elemIdx) => {
				elem.classList.add('fx--faded');
			});

			document.addEventListener('scroll', (e) => {
				let elemHead = document.querySelector('.page__head-wrapper');
				if( elemHead !== null ){
					if(window.scrollY > window.outerHeight){
						elemHead.classList.add('fixed');
					}else{
						elemHead.classList.remove('fixed');
					}
				}
			});

			window.addEventListener('popstate', checkHash);

			return () => {
				if( document.querySelector('.page__head h1') !== null ){
					let pageHead = document.querySelector('.page__head');
					pageHead.querySelector('h1').innerHTML = '';
					pageHead.querySelector('h3').innerHTML = '';
					pageHead.querySelector('p').innerHTML = '';
				}
				if( document.querySelector('.page__head .media') !== null ){
					// document.querySelector('.page__head .media').innerHTML = '';
				}
				document.removeEventListener('scroll', {});
				window.removeEventListener('popstate', checkHash);
				document.querySelector('body').style.overflow = '';
			};
		}
	// eslint-disable-next-line
	},[props.lang, slug])

	useEffect(() => {
		if( season !== null ){
			[...document.querySelectorAll('.fx--faded')].forEach((elem, elemIdx) => {
				setTimeout(() => {
					elem.classList.remove('fx--faded');
				}, elemIdx * 150);
			});
		}
	// eslint-disable-next-line
	}, [season])

	useEffect(() => {
		if( modalArticle !== null ){
			document.querySelector('body').style.overflow = 'hidden';
		}else{
			document.querySelector('body').style.overflow = '';
		}
		checkHash();
	// eslint-disable-next-line
	}, [modalArticle])


	const goToPrev = () => {
		let listIdx = props.lists.findIndex(x => x.slug === props.season.slug);
		if( listIdx > 0 ){
			let redirectUrl = `/${props.lang}/${props.lists[listIdx - 1].slug}`;
			history.push( redirectUrl );
			navigate( redirectUrl );
		}
	}

	const goToNext = () => {
		let listIdx = props.lists.findIndex(x => x.slug === props.season.slug);
			if( listIdx < props.lists.length - 1 ){
				let redirectUrl = `/${props.lang}/${props.lists[listIdx + 1].slug}`;
				history.push( redirectUrl );
				navigate( redirectUrl );
			}
	}

	const doModal = ( article ) => {
		window.location.hash = `${article.slug}`;
		setModalArticle(article);
	}

	const closeModal = () => {
		history.push(window.location.href.replace(window.location.hash, ""));
		setModalArticle(null);
	}

	const checkHash = () => {
        let hash = window.location.hash.replace('#', '');
        if( hash !== '' ){
            // let selectedArticle = props.section.translations.find( x => x.slug === hash);
            // doModal(modalArticle);
        }else{
            closeModal();
        }
    }

	const currentSeasonIdx = lists.findIndex(x => x.slug === slug);
	const gotoSeason = ( lastgotoPage !== undefined && currentSeasonIdx < lists.length - 1 ) 
		? lists[currentSeasonIdx+1] 
		: lastgotoPage[0]
	;

	return (
		<Fragment>

			{ season !== null && 
				<Helmet>
					<title>{translations[props.lang].title_short} - {season.translations[0].title}</title>
					<meta name="description" content={season.translations[0].description} />
				</Helmet>
			}

			<div className="page page--season">

				{/*
				<Sidebar 
					lists={lists}
					lang={props.lang}
					isVisible={(pageScrollIcon === true)}
					current={season}

				/>
				*/}

				<SectionHead 
					key={`section_head`} 
					lists={lists}
					season={season}
					lang={props.lang}
					goToPrev={goToPrev} 
					goToNext={goToNext}
					pageScrollIcon={pageScrollIcon}
				/>

				<div className="page__content">
					{ data.map( (section,sectionIdx) => 
						<SectionPropagator 
							key={`section_${sectionIdx}`} 
							sectionIdx={sectionIdx}
							section={section} 
							lang={props.lang}
							doModal={doModal}
						/>
					)}
				</div>

				{ season !== null && gotoSeason !== null &&
					<GoToSeason
						season={gotoSeason}
						lang={props.lang}
					/>
				}


				{/* 
				<div className={`page__links`}>

					{ season !== null && season.translations !== undefined && season.translations.length > 0 &&  
						<a 	key={`translation_link_top`} 
								href="#top"
								className="link link--section"
						>
							{season.translations[0].title}
						</a>
					}
					
					{ data.map( (section,sectionIdx) => {
						return (
							section.translations
								.filter(x => x.use_as_link === "1")
								.map( (translation,translationIdx) => {
								return ( translation.slug !== '' ) 
									? (<a 	key={`translation_link_${translationIdx}`} 
											href={`#${translation.slug}`}
											className="link link--section"
										>
											{translation.title}
										</a>
									)
									: (
										<span key={`translation_link_${translationIdx}`}></span>
									)
							})
						)
					})}

					<span key="translation_link_home"
						className="link link--season"
						onClick={() => { history.push(`/${props.lang}`);  navigate( `${process.env.PUBLIC_URL}/${props.lang}` );}}>
						{translations[language].seasons}
					</span>

				</div>
				*/}

			</div>

			{ modalArticle !== null && 
                <Fragment>
                    <div className="modal-season">

						<button 
							onClick={() => closeModal()}
							className={`btn btn--l btn--close`}
							style={{margin: 0 }}
                        >
							Zatvori
						</button>

                        <div className="modal-season__wrapper">

							{ modalArticle.title !== '' && 
								<h2 id={modalArticle.slug}>
									{modalArticle.title}
								</h2>
							}

							{ modalArticle.subtitle !== '' && 
								<h3>{modalArticle.subtitle}</h3>
							}

                            <p dangerouslySetInnerHTML={{__html: modalArticle.description}} />

                            { modalArticle.media
                                .filter( (elem,idx) => idx > 0 )
                                .map( (mediaItem, mediaItemIdx) => 
                                <div className="article__media"
                                    ref={el => itemsRef.current[`media_${mediaItemIdx}_${mediaItem.id}`] = el}
                                    key={`article_modal_media_${mediaItemIdx}`}>
                                    <Media 
                                        media={mediaItem}
                                        lightbox={false}
										loader
                                        cdnParams="&width=1200&func=crop"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                </Fragment>
            }

			<Footer  {...props} />

		</Fragment>
	);

}

export default Season;